import { memo, useCallback, Dispatch, SetStateAction } from 'react';
import { AppCardMemo } from '../app-card/app-card';
import styles from './slider-nav.module.css';

const imageSizes = `
(max-width: 640px) 50vw,
(max-width: 1280px) 33vw,
(max-width: 1920px) 25vw,
(max-width: 2560px) 20vw,
(max-width: 3200px) 16vw,
(max-width: 3840px) 14vw
`;

export interface SliderNavItemProps {
  // App data
  id: string;
  name: string;
  launchUrl: string;
  previewImageUrl: string;
  previewVideoUrl?: string;
  // Other props
  slideIndex: number;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
}

export function SliderNavItem({ slideIndex, setActiveSlideIndex, ...app }: SliderNavItemProps) {
  const onActivate = useCallback(() => {
    setActiveSlideIndex(slideIndex);
  }, [slideIndex, setActiveSlideIndex]);

  const onDeactivate = useCallback(() => {
    // Let's reset the active slide index to 0 when the card is deactivated IF
    // the active slide index is still the same as the slide index of this card.
    // Note that we have to be really careful here as the onActivate is often
    // called right before onDeactivate (in the same tick) so we have to do the
    // check in the state callback to ensure we're not resetting the active
    // slide index when it's already been updated.
    setActiveSlideIndex((activeSlideIndex) => {
      if (activeSlideIndex === slideIndex) return 0;
      return activeSlideIndex;
    });
  }, [slideIndex, setActiveSlideIndex]);

  return (
    <AppCardMemo
      className={styles.item}
      imageSizes={imageSizes}
      onActivate={onActivate}
      onDeactivate={onDeactivate}
      {...app}
    />
  );
}

export const SliderNavItemMemo = memo(SliderNavItem);

import { useEffect, useState, useRef, memo } from 'react';
import { SliderSlideMemo } from './slider-slide';
import { SliderNavMemo } from './slider-nav';
import { SlideData } from './types';
import { useStore } from '../../providers/store-provider';
import {
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_PADDING_ATTRIBUTE,
} from '@raybrowser/nav-system';
import styles from './slider.module.css';

export type SliderProps = {
  slides: SlideData[];
};

export function Slider({ slides }: SliderProps) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const isModalOpen = useStore(({ isModalOpen }) => !!isModalOpen);
  const isAnyCardActive = useStore(({ activeCardId }) => activeCardId !== null);
  const [isInViewport, setInViewport] = useState(true);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  // Monitor when the slider is not (enough) within the viewport.
  useEffect(() => {
    const sliderElem = sliderRef.current;
    if (!sliderElem) return;
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setInViewport(entry.isIntersecting);
        });
      },
      { threshold: 0.5 },
    );
    intersectionObserver.observe(sliderElem);
    return () => {
      intersectionObserver.disconnect();
    };
  }, [setInViewport]);

  return (
    <div
      ref={sliderRef}
      className={styles.root}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'y',
        [FOCUS_SCROLL_PADDING_ATTRIBUTE]: '0',
      }}
    >
      {slides.map((slide, index) => (
        <SliderSlideMemo
          key={`${index}-${slide.id}`}
          slide={slide}
          isActive={index === activeSlideIndex}
          isVideoPaused={!isInViewport || isModalOpen || (index === 0 && isAnyCardActive)}
          isFeatured={index === 0}
        />
      ))}

      {slides.length > 1 && (
        <SliderNavMemo slides={slides} setActiveSlideIndex={setActiveSlideIndex} />
      )}
    </div>
  );
}

export const SliderMemo = memo(Slider);

import { memo, useRef, useState, useEffect } from 'react';
import { useStore } from '../../providers/store-provider';
import styles from './app-card.module.css';

export type AppCardVideoProps = {
  videoUrl: string;
  isActive: boolean;
};

export function AppCardVideo({ videoUrl, isActive }: AppCardVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoHideListenerRef = useRef<(() => void) | null>(null);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const debugDisableVideos = useStore(({ debug }) => debug.disableVideos);

  // Play video once it's ready.
  useEffect(() => {
    if (!isActive || debugDisableVideos !== false) return;

    const videoElem = videoRef.current;
    if (!videoElem) return;

    let didUnmount = false;

    const playVideo = () => {
      // If the video is ended let's start it from the beginning.
      if (videoElem.ended) {
        videoElem.currentTime = 0;
      }

      videoElem
        .play()
        .then(() => {
          if (didUnmount) return;
          setVideoPlaying(true);
        })
        .catch((e) => {
          if (didUnmount) return;
          console.error(e);
        });
    };

    // Remove the transitionend listener if it's still there.
    if (videoHideListenerRef.current !== null) {
      videoElem.removeEventListener('transitionend', videoHideListenerRef.current);
      videoHideListenerRef.current = null;
    }

    // Play video if it's ready.
    if (videoElem.readyState === 4) {
      playVideo();
    }
    // Otherwise, let's wait for the video to be ready.
    else {
      videoElem.load();
      videoElem.addEventListener('canplaythrough', playVideo, { once: true });
    }

    return () => {
      const { currentTime, ended, paused } = videoElem;
      const sources = videoElem.querySelectorAll('source');

      didUnmount = true;

      videoElem.removeEventListener('canplaythrough', playVideo);

      // Pause video if it's playing.
      if (!paused) {
        videoElem.pause();
        setVideoPlaying(false);
      }

      // If video is not ended, let's load the video again without it's
      // sources to stop buffering and then restore the sources. But let's
      // do it after a short delay to prevent flickering during animations.
      if (!ended) {
        videoHideListenerRef.current = () => {
          videoHideListenerRef.current = null;
          // Make sure the video is still in the DOM. If the component unmounts
          // we don't want to do anything here.
          if (!videoElem.isConnected) return;
          sources.forEach((source) => source.remove());
          videoElem.load();
          videoElem.append(...sources);
          videoElem.currentTime = currentTime;
        };
        videoElem.addEventListener('transitionend', videoHideListenerRef.current, { once: true });
      }
    };
  }, [isActive, debugDisableVideos]);

  return videoUrl && !debugDisableVideos ? (
    <video
      key="video"
      ref={videoRef}
      className={isVideoPlaying ? styles.isPlaying : ''}
      muted={true}
      aria-hidden="true"
      preload="none"
      onEnded={() => setVideoPlaying(false)}
    >
      {videoUrl.split('.').pop() === 'mp4' ? (
        <source key="source" src={videoUrl} type="video/mp4"></source>
      ) : (
        <source key="source" src={videoUrl} type="video/webm"></source>
      )}
    </video>
  ) : null;
}

export const AppCardVideoMemo = memo(AppCardVideo);

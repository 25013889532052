import { memo, Dispatch, SetStateAction } from 'react';
import { SliderNavItemMemo } from './slider-nav-item';
import { CardScrollerMemo } from '../card-scroller/card-scroller';
import { BulletArrowDownIcon } from '../svg-icons/bullet-arrow-down';
import { SlideData } from './types';
import styles from './slider-nav.module.css';

export type SliderNavProps = {
  slides: SlideData[];
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
};

export function SliderNav({ slides, setActiveSlideIndex }: SliderNavProps) {
  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <h2 className={styles.headingTitle}>
          <BulletArrowDownIcon />
          Recently Played
        </h2>
      </div>
      <CardScrollerMemo className={styles.items}>
        {slides.map((slide, index) =>
          index === 0 ? null : (
            <SliderNavItemMemo
              key={index}
              id={slide.id}
              name={slide.name}
              launchUrl={slide.launchUrl}
              previewImageUrl={slide.previewImageUrl}
              slideIndex={index}
              setActiveSlideIndex={setActiveSlideIndex}
            />
          ),
        )}
      </CardScrollerMemo>
    </div>
  );
}

export const SliderNavMemo = memo(SliderNav);

import React, { useState, useEffect, CSSProperties } from 'react';
import styles from './shimmer.module.css';

export type ShimmerProps = {
  duration?: number;
  count?: number;
  visible?: boolean;
  onHover?: boolean;
  strength?: number;
};

export function Shimmer({
  duration = 600,
  visible = false,
  count = Infinity,
  onHover = false,
  strength = 8,
}: ShimmerProps) {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible, setIsVisible]);

  return (
    <div
      className={`${styles.root} ${isVisible ? styles.visible : ''} ${onHover ? styles.hover : ''}`}
      style={
        {
          ['--shimmer--strength']: `${strength}%`,
          ['--shimmer--duration']: `${duration}ms`,
          ['--shimmer--iteration-count']: count === Infinity ? 'infinite' : count.toString(),
        } as CSSProperties
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.ripple}></div>
      </div>
    </div>
  );
}

import { getStyleAsFloat } from './get-style-as-float';

export function getWidth(el: HTMLElement, type: 'content' | 'padding' | 'border' = 'border') {
  if (!window) return 0;
  let { width } = el.getBoundingClientRect();

  if (type === 'border') {
    return width;
  }

  // Remove borders from width.
  width -= getStyleAsFloat(el, 'borderLeftWidth');
  width -= getStyleAsFloat(el, 'borderRightWidth');

  if (type === 'padding') {
    return width;
  }

  // Remvove padding from width.
  width -= getStyleAsFloat(el, 'paddingLeft');
  width -= getStyleAsFloat(el, 'paddingRight');

  return width;
}

import { memo, useCallback, useRef, useEffect } from 'react';
import Image from 'next/image';
import { AppCardVideoMemo } from './app-card-video';
import { AnchorButton } from '../buttons/button/button';
import { AppDetailLink } from '../app-detail-link/app-detail-link';
import { ChevronDownIcon } from '../svg-icons/chevron-down-icon';
import { useSymbol } from '../../hooks/use-symbol';
import { useStore } from '../../providers/store-provider';
import {
  setCardDeactivationTimeout,
  clearCardDeactivationTimeout,
} from '../../utils/card-deactivation-timeout';
import {
  navSystem,
  ARROW_NAV_TARGET_CLASS,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '@raybrowser/nav-system';
import styles from './app-card.module.css';

const defaultImageSizes = `
(max-width: 640px) 50vw,
(max-width: 1280px) 33vw,
(max-width: 1920px) 25vw,
(max-width: 2560px) 20vw,
(max-width: 3200px) 16vw,
(max-width: 3840px) 14vw
`;

export type AppCardProps = {
  // App data
  id: string;
  name: string;
  launchUrl: string;
  previewImageUrl: string;
  previewVideoUrl?: string;
  // Other props
  className?: string;
  imageSizes?: string;
  onActivate?: () => void;
  onDeactivate?: () => void;
};

export function AppCard({
  className = '',
  imageSizes = defaultImageSizes,
  onActivate,
  onDeactivate,
  ...app
}: AppCardProps) {
  const cardId = useSymbol();
  const isActive = useStore(({ activeCardId }) => activeCardId === cardId);
  const addRecentlyPlayed = useStore(({ addRecentlyPlayed }) => addRecentlyPlayed);
  const setActiveCardId = useStore(({ setActiveCardId }) => setActiveCardId);
  const callbacksRef = useRef({ onActivate, onDeactivate });

  // Keep onActivate and onDeactivate up to date in the callbacksRef.
  callbacksRef.current = { onActivate, onDeactivate };

  const activateCard = useCallback(() => {
    clearCardDeactivationTimeout();
    if (!isActive) {
      setActiveCardId(cardId);
    }
  }, [cardId, isActive, setActiveCardId]);

  const deactivateCard = useCallback(() => {
    setCardDeactivationTimeout(() => {
      setActiveCardId(null);
    });
  }, [setActiveCardId]);

  const onPointerEnter = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      activateCard();
    }
  }, [activateCard]);

  const onPointerLeave = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      deactivateCard();
    }
  }, [deactivateCard]);

  const onFocus = useCallback(() => {
    activateCard();
  }, [activateCard]);

  const onBlur = useCallback(() => {
    deactivateCard();
  }, [deactivateCard]);

  // Call onActivate/onDeactivate when isActive changes.
  useEffect(() => {
    if (isActive) {
      callbacksRef.current.onActivate?.();
    } else {
      callbacksRef.current.onDeactivate?.();
    }
  }, [isActive]);

  return (
    <div
      className={`${styles.root} ${className}`}
      onPointerDown={onPointerEnter}
      onPointerUp={onPointerEnter}
      onPointerMove={onPointerEnter}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'x',
      }}
    >
      <div
        className={styles.interactionArea}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: 'x',
        }}
      ></div>
      <div className={styles.actions}>
        <AnchorButton
          className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
          theme="Primary"
          href={app.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => addRecentlyPlayed(app.id)}
        >
          Play
        </AnchorButton>
        <AppDetailLink className={styles.infoAction} theme="SecondaryLight" href={`?app=${app.id}`}>
          <ChevronDownIcon />
        </AppDetailLink>
      </div>
      <div className={styles.image}>
        <Image
          src={app.previewImageUrl}
          alt={app.name}
          draggable="false"
          fill
          quality={85}
          sizes={imageSizes}
        />
        {!!app.previewVideoUrl && (
          <AppCardVideoMemo isActive={isActive} videoUrl={app.previewVideoUrl} />
        )}
        <div className={styles.title}>
          <span className={styles.titleBg}></span>
          <span className={styles.titleText}>{app.name}</span>
        </div>
        <a
          className={styles.imageLink}
          href={app.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => addRecentlyPlayed(app.id)}
          tabIndex={-1}
          draggable={false}
        ></a>
      </div>
    </div>
  );
}

export const AppCardMemo = memo(AppCard);
